<i18n lang="yaml">
ja:
  menu:
    booth_maker: Vket Booth Makerとは
    booth_marche: Vket Booth Marcheとは
    contact: お問い合わせ
    faq: FAQ
    vket:
      button: バーチャルマーケット2024 Summer 申し込み
en:
  menu:
    booth_maker: About Vket Booth Maker
    booth_marche: About Vket Booth Marche
    contact: Contact Us
    faq: FAQ
    vket:
      button: Apply for VirtualMarket2024 Summer Exhibition
</i18n>

<template>
  <header class="ho-vket-booth-marche-header">
    <nav class="header-container">
      <div class="header-logo">
        <div class="link" @click="scrollToAnchor('top')">
          <LogoBooth class="image" alt="logo" />
        </div>
      </div>
      <div class="header-menu">
        <div class="link" @click="scrollToAnchor('maker')">
          {{ i18n.t('menu.booth_maker') }}
        </div>
        <div class="link" @click="scrollToAnchor('marche')">
          {{ i18n.t('menu.booth_marche') }}
        </div>
        <HaLink class="link" to="/contact">
          {{ i18n.t('menu.contact') }}
        </HaLink>
        <div class="link" @click="scrollToAnchor('faq')">
          {{ i18n.t('menu.faq') }}
        </div>
      </div>
      <div class="haeder-right">
        <client-only>
          <div class="header-sns">
            <HaLink
              class="link"
              to="https://discord.gg/qjGA3wygMz"
              :blank="true"
            >
              <IconDiscord class="image" alt="discord" />
              <IconDiscordColor class="image -color" alt="discord" />
            </HaLink>
            <HaLink
              class="link"
              to="https://www.instagram.com/vket_official/"
              :blank="true"
            >
              <IconInstagram class="image" alt="instagram" />
              <IconInstagramColor class="image -color" alt="instagram" />
            </HaLink>
            <HaLink
              class="link"
              to="https://twitter.com/Virtual_Market_"
              :blank="true"
            >
              <IconTwitter class="image" alt="twitter" />
              <IconTwitterColor class="image -color" alt="twitter" />
            </HaLink>
          </div>
        </client-only>
        <div class="myvket-link">
          <HaLink class="link" to="/">
            <LogoMyVket class="image" alt="MyVket" />
          </HaLink>
        </div>
        <div
          class="header-language"
          @click="onChangeLocale(i18n.locale.value === 'ja' ? 'en' : 'ja')"
        >
          <span :class="{ '-active': i18n.locale.value === 'ja' }">JP</span>
          <span :class="{ '-active': i18n.locale.value === 'en' }">EN</span>
        </div>
      </div>
      <template v-if="!isOpen">
        <div class="hamburger-menu" @click="ClickHamburgerMenu()">
          <span />
          <span />
          <span />
        </div>
      </template>
      <template v-else>
        <div class="close-button" @click="ClickHamburgerMenu()">
          <span />
          <span />
        </div>
        <div class="sp-menu">
          <div class="menu">
            <div class="link" @click="scrollToAnchor('maker')">
              {{ i18n.t('menu.booth_maker') }}
            </div>
            <div class="link" @click="scrollToAnchor('marche')">
              {{ i18n.t('menu.booth_marche') }}
            </div>
            <HaLink class="link" to="/contact">
              {{ i18n.t('menu.contact') }}
            </HaLink>
            <div class="link" @click="scrollToAnchor('faq')">
              {{ i18n.t('menu.faq') }}
            </div>
          </div>
          <div class="sns-link">
            <HaLink
              class="link"
              to="https://discord.gg/qjGA3wygMz"
              :blank="true"
            >
              <HaBaseImage
                src="/images/vket_booth_marche/sns/icon_discord.png"
                class="image"
              />
            </HaLink>
            <HaLink
              class="link"
              to="https://www.instagram.com/vket_official/"
              :blank="true"
            >
              <HaBaseImage
                src="/images/vket_booth_marche/sns/icon_Instagram.png"
                class="image"
              />
            </HaLink>
            <HaLink
              class="link"
              to="https://twitter.com/Virtual_Market_"
              :blank="true"
            >
              <HaBaseImage
                src="/images/vket_booth_marche/sns/icon_twitter.png"
                class="image"
              />
            </HaLink>
          </div>
          <div class="myvket-button">
            <HaLink class="link" to="/">
              <LogoMyVket class="image" alt="MyVket" />
            </HaLink>
          </div>
          <div
            class="sp-language"
            @click="onChangeLocale(i18n.locale.value === 'ja' ? 'en' : 'ja')"
          >
            <span :class="{ '-active': i18n.locale.value === 'ja' }">JP</span>
            <span :class="{ '-active': i18n.locale.value === 'en' }">EN</span>
          </div>
        </div>
      </template>
    </nav>
  </header>
</template>

<script setup lang="ts">
// assets
import LogoBooth from '@/assets/images/vket_booth_marche/logo-vket-booth.svg'
import IconDiscord from '@/assets/images/vket_booth_marche/sns/icon-discord.svg'
import IconDiscordColor from '@/assets/images/vket_booth_marche/sns/icon-discord-color.svg'
import IconTwitter from '@/assets/images/vket_booth_marche/sns/icon-twitter.svg'
import IconTwitterColor from '@/assets/images/vket_booth_marche/sns/icon-twitter-color.svg'
import IconInstagram from '@/assets/images/vket_booth_marche/sns/icon-instagram.svg'
import IconInstagramColor from '@/assets/images/vket_booth_marche/sns/icon-instagram-color.svg'
import LogoMyVket from '@/assets/images/logo-my-vket.svg'

// modules
import { scrollToId } from '@/utils/scroll'

const i18n = useI18n()
const locale = useLocale()

const isOpen = ref<boolean>(false)

const scrollToAnchor = (id: string) => {
  scrollToId(id, { add: -80 })
  isOpen.value = false
}

const onChangeLocale = async (lang: 'ja' | 'en') => {
  await locale.changeLocale(lang)
  isOpen.value = false
}

const ClickHamburgerMenu = () => {
  isOpen.value = !isOpen.value
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-vket-booth-marche-header {
  background: v.$gray-9;
  margin-top: -16px;
  overflow: visible;
  padding: 16px 0;
  position: fixed;
  width: 100%;
  z-index: v.$zindex-header;
  @include m.sp {
    height: auto;
    margin-top: -8px;
    padding: 8px 0;
  }
}

.header-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  width: 100%;
}

.header-logo {
  width: fit-content;

  > .link {
    background: v.$white;
    cursor: pointer;
    display: flex;
    height: fit-content;
    margin: 0;

    &::after {
      border-color: transparent v.$gray-9 transparent transparent;
      border-style: solid;
      border-width: 64px 136px 0 0;
      content: '';
      display: block;

      @include m.sp {
        border-width: 56px 64px 0 0;
        width: 64px;
      }
    }
  }

  > .link > .image {
    display: flex;
    height: 64px;
    min-width: 160px;
    padding: 2px;
    width: 100%;

    @include m.sp {
      height: 56px;
      margin: 0;
    }
  }
}

.header-menu {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: left;
  overflow: hidden;
  width: 100%;

  @include m.sp {
    display: none;
  }
  @include m.tb {
    display: none;
  }

  > .link {
    border-right: solid 1px v.$white;
    color: v.$white;
    cursor: pointer;
    display: block;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0;
    padding: 0 5%;
    text-align: center;
    white-space: nowrap;
    width: fit-content;

    &:last-child {
      border-width: 0;
    }

    &::after {
      background: v.$white;
      content: '';
      display: block;
      height: 1px;
      margin: 0 auto;
      opacity: 0;
      transition: 0.3s;
      width: 0;
    }

    &:hover {
      &::after {
        opacity: 1;
        width: 100%;
      }
    }
  }
}

.haeder-right {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0 0 0 auto;

  @include m.sp {
    display: none;
  }
}

.header-sns {
  display: flex;
  flex-direction: row;

  > .link {
    height: fit-content;
    margin: 8px;
    position: relative;
    width: fit-content;
  }

  > .link > .image {
    display: block;
    height: 36px;
    transition: 0.3s;
    width: 36px;

    &.-color {
      opacity: 0;
      position: absolute;
      top: 0;
    }

    &:hover {
      &.-color {
        opacity: 1;
      }
    }
  }
}

.myvket-link {
  background: v.$white;
  border: solid 2px v.$orange-1;
  border-radius: 36px;
  height: 48px;

  > .link {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 30px;
    width: 100%;
  }

  > .link > .image {
    display: block;
    fill: v.$orange-1;
    height: 24px;
  }

  &:hover {
    background: v.$orange-1;

    > .link > .image {
      fill: v.$white;
    }
  }
}

.header-language {
  border-radius: 36px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 48px;
  margin: 0 36px;
  overflow: hidden;

  span {
    align-items: center;
    background: v.$gray-5;
    color: v.$gray-1;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    width: 32px;

    &.-active {
      background: v.$white;
      color: v.$black;
    }
  }
}

.hamburger-menu {
  cursor: pointer;
  display: none;
  flex-direction: column;
  flex-shrink: 0;
  height: 24px;
  justify-content: space-between;
  margin-right: 24px;
  width: 26px;
  @include m.sp {
    display: flex;
  }

  span {
    background: v.$white;
    display: block;
    height: 3px;
    width: 100%;

    &:first-child {
      width: 80%;
    }
  }
}

.close-button {
  cursor: pointer;
  display: none;
  flex-direction: column;
  flex-shrink: 0;
  height: 24px;
  justify-content: space-between;
  margin-right: 24px;
  position: relative;
  width: 26px;
  @include m.sp {
    display: block;
  }

  span {
    background: v.$white;
    display: block;
    height: 3px;
    position: absolute;
    top: 50%;
    width: 100%;

    &:first-child {
      transform: translateY(-50%) rotate(45deg);
    }

    &:last-child {
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}

.sp-menu {
  background: rgba(38, 38, 38, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  margin-left: -8px;
  min-height: 100vh;
  overflow: scroll;
  padding: 8px 48px;
  position: absolute;
  transform: translateY(100%);
  width: 100vw;
  z-index: v.$zindex-header - 1;

  > .menu {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  > .menu > .link {
    border-bottom: 1px solid v.$white;
    color: v.$white;
    font-size: 18px;
    font-weight: 600;
    padding: 16px 0;
    text-align: center;
    width: 100%;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.vket-button {
  background: v.$white;
  border-radius: 8px;
  padding: 8px 0;
  position: relative;
  text-align: center;
  width: 100%;

  > .logo {
    margin: 0 auto;
    width: 70%;
  }

  > .button {
    font-size: 12px;
    max-width: 100%;
    position: absolute;
    white-space: nowrap;
    width: 100%;
  }
}

.sns-link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px auto 0;

  > .link {
    width: 25%;
  }
}

.myvket-button {
  background: v.$white;
  border: solid 2px v.$orange-1;
  border-radius: 36px;
  margin-top: 32px;

  > .link {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 8px 0;
    width: 100%;
  }

  > .link > .image {
    display: block;
    fill: v.$orange-1;
    height: 32px;
    margin: 0 auto;
  }
}

.sp-language {
  border-radius: 36px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: fit-content;
  margin: 32px auto 128px;
  min-height: 48px;
  overflow: hidden;
  width: 80%;

  span {
    align-items: center;
    background: v.$gray-5;
    color: v.$gray-1;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    min-height: 100%;
    width: 50%;

    &.-active {
      background: v.$white;
      color: v.$black;
    }
  }
}
</style>
