<i18n lang="yaml">
ja:
  marche: |
    Vket Booth Marche
    会場はこちら
  blank: 別ウィンドウで見る
en:
  marche: |
    Visit the Vket Booth Marche!
  blank: Open in a New Window
</i18n>
<template>
  <div class="vket-booth-marche-layout">
    <HoVketBoothMarcheHeader class="ho-header" />
    <slot />
    <div class="buttons">
      <template v-if="isMarcheOpen">
        <div class="button-container" @click="ClickModalButton">
          <HoVketBoothMarcheButton
            class="button"
            color="border-orange"
            :disabled="true"
          >
            {{ i18n.t('marche') }}
          </HoVketBoothMarcheButton>
        </div>
      </template>
      <template v-else>
        <ClientOnly>
          <template v-if="isJa">
            <div class="button-container -disabled">
              <HoVketBoothMarcheButton
                class="button"
                color="gray"
                :disabled="true"
              >
                開催まであと
                {{
                  diffDays(
                    formatDate('YYYY-MM-DD', new Date()),
                    vketBoothMarcheStartDateTime
                  )
                }}
                日
                {{
                  Number(
                    diffDays(
                      formatDate('YYYY-MM-DD', new Date()),
                      vketBoothMarcheStartDateTime,
                      'hour'
                    )
                  ) % 24
                }}
                時間
                {{
                  Number(
                    diffDays(
                      formatDate('YYYY-MM-DD', new Date()),
                      vketBoothMarcheStartDateTime,
                      'minutes'
                    )
                  ) % 60
                }}
                分
              </HoVketBoothMarcheButton>
            </div>
          </template>
          <template v-else>
            <div class="button-container -disabled">
              <HoVketBoothMarcheButton
                class="button"
                color="gray"
                :disabled="true"
              >
                Coming soon...
                {{
                  diffDays(
                    formatDate('YYYY-MM-DD', new Date()),
                    vketBoothMarcheStartDateTime
                  )
                }}
                Days
                {{
                  Number(
                    diffDays(
                      formatDate('YYYY-MM-DD', new Date()),
                      vketBoothMarcheStartDateTime,
                      'hour'
                    )
                  ) % 24
                }}
                Hours
                {{
                  Number(
                    diffDays(
                      formatDate('YYYY-MM-DD', new Date()),
                      vketBoothMarcheStartDateTime,
                      'minutes'
                    )
                  ) % 60
                }}
                Mins
              </HoVketBoothMarcheButton>
            </div>
          </template>
        </ClientOnly>
      </template>
    </div>
    <HoVketBoothMarcheFooter class="ho-footer" />
    <div class="marche-modal" :class="{ ['-open']: isModalOpen }">
      <div class="container">
        <template v-if="isDisplayIframe">
          <iframe
            class="ingame"
            src="https://boothmarche.cloud.vket.com/worlds/24Summer_BoothMarche/main.html?worldid=24Summer_BoothMarche"
            scrolling="no"
            allow="microphone"
          />
        </template>
        <HaLink
          class="link-text"
          to="https://boothmarche.cloud.vket.com/worlds/24Summer_BoothMarche/main.html?worldid=24Summer_BoothMarche"
          :blank="true"
        >
          {{ i18n.t('blank') }}
          <IconExternal class="image" />
        </HaLink>
        <div class="close-button" @click="ClickModalButton">
          <IconClose class="image" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import IconClose from '@/assets/icons/icon-close-solid.svg'
import IconExternal from '@/assets/icons/icon-external.svg'
// modules
import {
  vketBoothMarcheStartDateTime,
  formatDate,
  isBeforeTargetDate,
  diffDays,
} from '@/utils/date-control'

const i18n = useI18n()
const isJa = computed(() => i18n.locale.value === 'ja')

const isMarcheOpen = computed(
  () => !isBeforeTargetDate(vketBoothMarcheStartDateTime)
)
const isModalOpen = ref(false)
const isDisplayIframe = ref(false)

const ClickModalButton = () => {
  isModalOpen.value = !isModalOpen.value
  if (!isDisplayIframe.value) {
    isDisplayIframe.value = !isDisplayIframe.value
    return
  }
  setTimeout(() => {
    isDisplayIframe.value = !isDisplayIframe.value
  }, 500)
}

onMounted(() => {
  isModalOpen.value = isMarcheOpen.value
  isDisplayIframe.value = isMarcheOpen.value
})
</script>
<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

/** layout */
.vket-booth-marche-layout {
  background: v.$gray-9;
  padding: 16px;

  @include m.sp {
    padding: 8px;
  }

  > .buttons {
    bottom: 32px;
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-content: center;
    margin-top: -160px;
    position: sticky;
    width: 100%;
    z-index: v.$zindex-header - 2;
    @include m.sp {
      align-items: flex-end;
      bottom: 16px;
      flex-direction: column;
    }
  }
}

.button-container {
  cursor: pointer;

  > .button {
    animation: 0.3s popUp;
    margin: 0 16px;
    max-width: unset;
    padding: 0 32px 0 16px;
    width: fit-content;
    @include m.sp {
      border-radius: 8px 0 0 8px;
      font-size: 14px;
      margin: 8px 0;
      padding: 0 32px 0 4px;
      right: -8px;
    }
  }

  > .button :deep(.link) {
    white-space: nowrap;
  }
  @include m.sp {
    > .button :deep(.link) {
      white-space: break-spaces;
    }
  }

  &.-disabled {
    cursor: default;
  }
}

.marche-modal {
  background: rgba(0, 0, 0, 0);
  display: block;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transition: 0.5s;
  width: 100vw;
  z-index: -1;

  &.-open {
    background: rgba(0, 0, 0, 0.6);
    z-index: v.$zindex-toast;

    > .container {
      top: 50%;
      @include m.sp {
        top: 0;
      }
    }
  }

  > .container {
    align-items: center;
    background: v.$red-2;
    border: 16px solid v.$red-2;
    border-radius: 16px;
    display: flex;
    filter: drop-shadow(8px 8px 0 v.$gray-9);
    flex-direction: column;
    height: auto;
    left: 50%;
    position: relative;
    top: 150%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    width: 75vw;
    @include m.sp {
      align-items: flex-start;
      border: 8px solid v.$red-2;
      border-radius: 0;
      height: 100vh;
      left: 0;
      transform: unset;
      width: 100vw;
    }
  }

  > .container > .ingame {
    aspect-ratio: 16/9;
    border-radius: 16px;
    height: auto;
    object-fit: contain;
    width: 100%;
    @include m.sp {
      border-radius: 8px;
      height: 100%;
    }
  }
}

.link-text {
  align-items: center;
  color: v.$yellow-2;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  margin: 8px 0 -2px;

  > .image {
    height: 16px;
    margin-left: 8px;
    width: 16px;
  }

  > .image :deep(path) {
    fill: v.$yellow-2;
  }
  @include m.sp {
    margin: 16px auto 16px 8px;
  }
}

.close-button {
  background: v.$yellow-2;
  border: 4px solid v.$red-2;
  border-radius: 50%;
  cursor: pointer;
  height: 64px;
  padding: 16px;
  position: absolute;
  right: -40px;
  top: -40px;
  width: 64px;

  > .image {
    height: 100%;
    width: 100%;
  }

  > .image :deep(path) {
    fill: v.$red-2;
  }

  @include m.sp {
    bottom: 0;
    height: 48px;
    padding: 8px;
    right: 0;
    top: unset;
    width: 48px;
  }
}

@keyframes popUp {
  0% {
    filter: none;
    transform: translate(8px, 8px);
  }

  100% {
    filter: drop-shadow(8px 8px 0 v.$gray-9);
    transform: translate(0, 0);
  }
}
</style>
