<i18n lang="yaml">
ja:
  privacy: プライバシーポリシー
en:
  privacy: Privacy Policy
</i18n>
<template>
  <footer class="ho-vket-booth-marche-footer">
    <div class="border">
      <span />
    </div>
    <div class="container">
      <LogoHikky class="logo" />
      <HaLink class="privacy" to="https://account.vket.com/terms#privacy">
        {{ i18n.t('privacy') }}
      </HaLink>
      <p class="copyright">© HIKKY All rights reserved.</p>
    </div>
  </footer>
</template>
<script setup lang="ts">
// assets
import LogoHikky from '@/assets/images/logo-hikky_white.svg'

const i18n = useI18n()
</script>
<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-vket-booth-marche-footer {
  background: transparent;
  margin-top: 160px;
  position: relative;

  > .border {
    display: flex;
    height: 64px;
    position: absolute;
    top: -124px;
    width: 100%;

    @include m.sp {
      height: 32px;
      top: -32px;
    }

    span {
      background: v.$gray-9;
      content: '';
      display: block;
      flex-direction: row;
      width: 55%;
    }

    &::after {
      border-color: v.$gray-9 transparent v.$gray-9 v.$gray-9;
      border-style: solid;
      border-width: 64px 96px 0 0;
      content: '';
      display: block;
      width: 96px;
      @include m.sp {
        border-width: 32px 48px 0 0;
      }
    }
  }

  > .container {
    background: v.$gray-9;
    color: v.$white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include m.sp {
      padding-top: 16px;
    }
  }

  > .container > .logo {
    height: 64px;
    margin-top: -16px;
  }

  > .container > .privacy {
    color: v.$white;
    font-size: 14px;
    margin: 48px auto;
    text-align: center;
    width: fit-content;
  }

  > .container > .copyright {
    font-size: 16px;
    margin-bottom: 8px;
    text-align: center;
    @include m.sp {
      margin-bottom: 48px;
    }
  }
}
</style>
